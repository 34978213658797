import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../actions";
function EnsureLoggedIn(props) {
  var navigate = useNavigate();
  var dispatch = useDispatch();
  var _useSelector = useSelector(function (state) {
      return state.authenticationStatus;
    }),
    forceLogout = _useSelector.forceLogout;
  useEffect(function () {
    if (!forceLogout) return;
    dispatch(logout()).then(function () {
      return navigate("/");
    });
  }, [forceLogout]);
  return props.children;
}
export default EnsureLoggedIn;