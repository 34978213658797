var _classNames;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import moment from "moment";
import Dinero from 'dinero.js';
export var safeMarginPercentage = 0.1;
export var minimumRequestedCurrencyAmount = 100;
export var defaultPageSize = 10;
export var domains = {
  // FRONT_COM_AR: 'front.com.ar.local',
  FRONT_LA: "front.la" //.local'
};

export var invertirOnlineCurrencies = {
  peso_Argentino: "peso_Argentino"
};
export var cafciMonedaCafciCodes = {
  ARS: "ARS",
  SAsig: "SAsig",
  USD: "USD",
  ECU: "ECU",
  USB: "USB"
};
export var brokerIds = {
  SBS: 1,
  COHEN: 2,
  FRONT: 3,
  INVERTIRONLINE: 4,
  ADCAP: 5,
  BITEX: 6
};
export var investmentItemManagerIds = {
  CAFCI: 1,
  INVERTIRONLINE: 2,
  BITEX: 3
};
export var workingStatusIds = {
  EMPLOYEE: 1,
  SELFEMPLOYED: 2,
  STUDENT: 3,
  UNEMPLOYED: 4,
  RETIRED: 5,
  PENSIONER: 6
};
export var maritalStatusIds = {
  SINGLE: 1,
  MARRIED: 2,
  DIVORCED: 3,
  WIDOWED: 4
};
export var incomeTypeIds = {
  DIVIDEND: 1,
  INTEREST: 2,
  BOND_AMORTIZATION: 3,
  BOND_INTEREST: 4
};
export var investmentItemTypeIds = {
  FUND: 1,
  CEDEAR: 2,
  CURRENCY: 3,
  CRYPTO: 4,
  STOCK: 5,
  BOND: 6,
  BILL: 7,
  CORPORATE_BOND: 8
};
export var perspectives = {
  LIST: "LIST",
  ARRAY: "ARRAY",
  BUBBLE: "BUBBLE"
};
export var dateFormats = {
  MINUTES: "MINUTES",
  HOURS: "HOURS",
  DAYS: "DAYS",
  WEEKS: "WEEKS"
};
export var classNames = (_classNames = {
  GOAL: "ar.com.front.model.Goal",
  INVESTMENT_ACCOUNT: "ar.com.front.model.InvestmentAccount",
  POST: "ar.com.front.model.Post",
  OPERATION: "ar.com.front.model.Operation",
  USER: "ar.com.front.model.User",
  USER_FOLLOWER: "ar.com.front.model.UserFollower",
  INVESTMENT_ITEM: "ar.com.front.model.InvestmentItem",
  BOOST_CURRENCY_BALANCE: "ar.com.front.model.BoostCurrencyBalance",
  BOOST_CURRENCY_INVESTMENT_ITEM_BALANCE: "ar.com.front.model.BoostCurrencyInvestmentItemBalance",
  BOOST_INVESTMENT_ITEM_BALANCE: "ar.com.front.model.BoostInvestmentItemBalance",
  BROKER_CURRENCY_BALANCE: "ar.com.front.model.BrokerCurrencyBalance",
  BROKER_CURRENCY_GOAL_INVESTMENT_ITEM_USER_BALANCE: "ar.com.front.model.BrokerCurrencyGoalInvestmentItemUserBalance",
  BROKER_CURRENCY_GOAL_USER_BALANCE: "ar.com.front.model.BrokerCurrencyGoalUserBalance",
  BROKER_CURRENCY_INVESTMENT_ITEM_BALANCE: "ar.com.front.model.BrokerCurrencyInvestmentItemBalance",
  BROKER_CURRENCY_INVESTMENT_ITEM_USER_BALANCE: "ar.com.front.model.BrokerCurrencyInvestmentItemUserBalance",
  BROKER_CURRENCY_USER_BALANCE: "ar.com.front.model.BrokerCurrencyUserBalance",
  CURRENCY_BALANCE: "ar.com.front.model.CurrencyBalance",
  CURRENCY_GOAL_BALANCE: "ar.com.front.model.CurrencyGoalBalance",
  CURRENCY_GOAL_INVESTMENT_ITEM_BALANCE: "ar.com.front.model.CurrencyGoalInvestmentItemBalance",
  CURRENCY_GOAL_INVESTMENT_ITEM_USER_BALANCE: "ar.com.front.model.CurrencyGoalInvestmentItemUserBalance",
  CURRENCY_GOAL_USER_BALANCE: "ar.com.front.model.CurrencyGoalUserBalance",
  CURRENCY_INVESTMENT_ITEM_BALANCE: "ar.com.front.model.CurrencyInvestmentItemBalance",
  CURRENCY_INVESTMENT_ITEM_USER_BALANCE: "ar.com.front.model.CurrencyInvestmentItemUserBalance",
  CURRENCY_USER_BALANCE: "ar.com.front.model.CurrencyUserBalance"
}, _defineProperty(_classNames, "POST", "ar.com.front.model.Post"), _defineProperty(_classNames, "HASHTAG", "ar.com.front.model.Hashtag"), _defineProperty(_classNames, "CURRENCY_VARIANT_INVESTMENT_ITEM", "ar.com.front.model.CurrencyVariantInvestmentItem"), _defineProperty(_classNames, "BROKER_VARIANT_INVESTMENT_ITEM", "ar.com.front.model.BrokerVariantInvestmentItem"), _defineProperty(_classNames, "BALANCE", "ar.com.front.model.Balance"), _defineProperty(_classNames, "INVESTMENT_PLAN_FRACTION", "ar.com.front.model.InvestmentPlanFraction"), _classNames);
export var isVairant = function isVairant(investmentItem) {
  return investmentItem.className === classNames.CURRENCY_VARIANT_INVESTMENT_ITEM || investmentItem.className === classNames.BROKER_VARIANT_INVESTMENT_ITEM;
};
export var timeUnits = {
  MONTHS: "MONTHS",
  YEARS: "YEARS",
  DAYS: "DAYS"
};
export var balanceDataTypes = {
  CURRENCY_AMOUNT: "CURRENCY_AMOUNT",
  PROFIT: "PROFIT"
};
export var priceDataTypes = {
  VALUE: "VALUE",
  PERCENTAGE: "PERCENTAGE"
};
export var periods = {
  HISTORIC: "HISTORIC",
  MINUS_1Y: "MINUS_1Y",
  MINUS_6M: "MINUS_6M",
  MINUS_3M: "MINUS_3M",
  MINUS_1M: "MINUS_1M",
  MINUS_1W: "MINUS_1W",
  MINUS_1D: "MINUS_1D",
  PLUS_1W: "PLUS_1W",
  PLUS_1M: "PLUS_1M",
  PLUS_2M: "PLUS_2M"
};
export var mapPeriodToTimeRange = function mapPeriodToTimeRange() {
  var _ref;
  var zeroInstantStr = moment().endOf("day").toISOString();
  return _ref = {}, _defineProperty(_ref, periods.HISTORIC, {
    toInstant: zeroInstantStr,
    fromInstant: "1970-01-01T00:00:00.000Z",
    dateFormat: dateFormats.WEEKS
  }), _defineProperty(_ref, periods.MINUS_1Y, {
    toInstant: zeroInstantStr,
    fromInstant: moment(zeroInstantStr).startOf("day").subtract(1, "years").toISOString(),
    dateFormat: dateFormats.DAYS
  }), _defineProperty(_ref, periods.MINUS_6M, {
    toInstant: zeroInstantStr,
    fromInstant: moment(zeroInstantStr).startOf("day").subtract(6, "months").toISOString(),
    dateFormat: dateFormats.DAYS
  }), _defineProperty(_ref, periods.MINUS_3M, {
    toInstant: zeroInstantStr,
    fromInstant: moment(zeroInstantStr).startOf("day").subtract(3, "months").toISOString(),
    dateFormat: dateFormats.DAYS
  }), _defineProperty(_ref, periods.MINUS_1M, {
    toInstant: zeroInstantStr,
    fromInstant: moment(zeroInstantStr).startOf("day").subtract(1, "months").toISOString(),
    dateFormat: dateFormats.HOURS
  }), _defineProperty(_ref, periods.MINUS_1W, {
    toInstant: zeroInstantStr,
    fromInstant: moment(zeroInstantStr).startOf("day").subtract(1, "weeks").toISOString(),
    dateFormat: dateFormats.MINUTES
  }), _defineProperty(_ref, periods.MINUS_1D, {
    toInstant: moment().utcOffset(0, true).set({
      hour: 18,
      minute: 0,
      second: 0,
      millisecond: 0
    }).toISOString(),
    fromInstant: moment().utcOffset(0, true).set({
      hour: 11,
      minute: 0,
      second: 0,
      millisecond: 0
    }).toISOString(),
    dateFormat: dateFormats.MINUTES
  }), _defineProperty(_ref, periods.PLUS_1W, {
    toInstant: moment(zeroInstantStr).endOf("day").add(1, "weeks").toISOString(),
    fromInstant: zeroInstantStr,
    dateFormat: dateFormats.MINUTES
  }), _defineProperty(_ref, periods.PLUS_1M, {
    toInstant: moment(zeroInstantStr).endOf("day").add(1, "months").toISOString(),
    fromInstant: zeroInstantStr,
    dateFormat: dateFormats.HOURS
  }), _defineProperty(_ref, periods.PLUS_2M, {
    toInstant: moment(zeroInstantStr).endOf("day").add(2, "months").toISOString(),
    fromInstant: zeroInstantStr,
    dateFormat: dateFormats.DAYS
  }), _ref;
};
export var commissionTypes = {
  MARKET_RIGHT_COMMISSION: "MARKET_RIGHT_COMMISSION",
  DUTY_COMMISSION: "DUTY_COMMISSION",
  REGISTRATION_RIGHT_COMMISSION: "REGISTRATION_RIGHT_COMMISSION",
  DEPOSIT_COMMISSION: "DEPOSIT_COMMISSION",
  WITHDRAW_COMMISSION: "WITHDRAW_COMMISSION",
  TRADE_COMMISSION: "TRADE_COMMISSION"
};
export var transactionTypes = {
  BUY: "BUY",
  SELL: "SELL",
  TRANSFER: "TRANSFER"
};
export var operationTypes = {
  BUY: "BUY",
  SELL: "SELL",
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW"
};
export var operationStateIds = {
  INCART: 1,
  PENDING: 2,
  PENDING_REBALANCE: 3,
  PENDING_REVISION: 4,
  SUBMITTED: 5,
  COMMITTED: 6,
  REJECTED: 7,
  CANCELED: 8
};
export var engagedOperationStateIdsStr = [operationStateIds.PENDING, operationStateIds.SUBMITTED, operationStateIds.PENDING_REVISION].join(",");
export var transactionStates = {
  GOAL_TRANSFER: "GOAL_TRANSFER",
  USER_TRANSFER: "USER_TRANSFER"
};
export var memberStates = {
  DELETABLE: "DELETABLE",
  ONGOING: "ONGOING",
  PENDING_FINALIZE: "PENDING_FINALIZE",
  FINALIZED: "FINALIZED"
};
export var boostStates = {
  USABLE: "USABLE",
  ONGOING: "ONGOING",
  USED: "USED",
  EXPIRED: "EXPIRED"
};
export var documentTypeIds = {
  DNI_FRONTSIDE: 1,
  DNI_BACKSIDE: 2,
  PAYSLIP: 3,
  SIGNATURE: 4,
  SELFIE_HOLDING_DOCUMENT: 5,
  SELFIE_NEUTRAL: 6,
  SELFIE_SMILING: 7,
  STUDENT_PROOF: 8,
  SIGNED_INCOME_CERTIFICATE: 9,
  REGISTRABLE_ASSETS_INCOME: 10,
  INCOME_SWORN_DECLARATION: 11,
  UIF_DOCUMENT: 12,
  ADDRESS_PROOF: 13,
  CEDULA_FRONTSIDE: 14,
  CEDULA_BACKSIDE: 15
};
export var goalTypeIds = {
  GO_ON_VACATION: 2,
  MOVE: 4,
  NEW_CAR: 41,
  EMERGENCY_FUND: 289,
  FINANCIAL_FREEDOM: 379
};
export var roleIds = {
  USER: 1,
  ADMIN: 2
};
export var roleNames = {
  ROLE_USER: "ROLE_USER",
  ROLE_ADMIN: "ROLE_ADMIN"
};
export var eventTypeNames = {
  BUY_PENDING: "BUY_PENDING",
  SELL_PENDING: "SELL_PENDING",
  BUY_SUBMITTED: "BUY_SUBMITTED",
  SELL_SUBMITTED: "SELL_SUBMITTED",
  BUY_COMMITTED: "BUY_COMMITTED",
  SELL_COMMITTED: "SELL_COMMITTED",
  BUY_REJECTED: "BUY_REJECTED",
  SELL_REJECTED: "SELL_REJECTED",
  BUY_WITH_BOOST_COMMITTED: "BUY_WITH_BOOST_COMMITTED",
  SELL_WITH_BOOST_COMMITTED: "SELL_WITH_BOOST_COMMITTED",
  MEMBER_CREATED: "MEMBER_CREATED",
  MEMBER_CONFIRMED: "MEMBER_CONFIRMED",
  INVESTMENTACCOUNT_CREATED: "INVESTMENTACCOUNT_CREATED",
  INVESTMENTACCOUNT_ENABLED: "INVESTMENTACCOUNT_ENABLED",
  POST_CREATED: "POST_CREATED",
  POSTREACTION_CREATED: "POSTREACTION_CREATED",
  REACTION_CREATED: "REACTION_CREATED",
  USERFOLLOWER_CREATED: "USERFOLLOWER_CREATED",
  USERFOLLOWER_CONFIRMED: "USERFOLLOWER_CONFIRMED",
  BOOST_ONGOING: "BOOST_ONGOING",
  BOOST_USED: "BOOST_USED",
  GOAL_CREATED: "GOAL_CREATED",
  USERMENTION_CREATED: "USERMENTION_CREATED",
  PARTICIPANT_CREATED: "PARTICIPANT_CREATED",
  MEMBER_FINALIZED: "MEMBER_FINALIZED",
  BOOST_CREATED: "BOOST_CREATED",
  TIME_FOR_INVESTOR_LESSON_1: "TIME_FOR_INVESTOR_LESSON_1",
  TIME_FOR_INVESTOR_LESSON_2: "TIME_FOR_INVESTOR_LESSON_2",
  TIME_FOR_INVESTOR_LESSON_3: "TIME_FOR_INVESTOR_LESSON_3",
  BROKERINVESTMENTITEMUSEREXTERNALBALANCE_INCREMENTED: "BROKERINVESTMENTITEMUSEREXTERNALBALANCE_INCREMENTED",
  DEPOSIT_PENDING: "DEPOSIT_PENDING",
  GOOGLE_AD_RENDERED: "GOOGLE_AD_RENDERED",
  POSTREPLY_CREATED: "POSTREPLY_CREATED",
  EVENTREPLY_CREATED: "EVENTREPLY_CREATED",
  POSTREPUBLISHER_CREATED: "POSTREPUBLISHER_CREATED",
  EVENTREPUBLISHER_CREATED: "EVENTREPUBLISHER_CREATED"
};
export var exclusionPreferenceTypes = {
  NOTIFICATION: "NOTIFICATION",
  FEED: "FEED"
};
export var emojiNames = {
  CLAP: "CLAP",
  HEART: "HEART",
  THUMBS_UP: "THUMBS_UP"
};
export var currencyCodes = {
  ARS: "ARS",
  USD: "USD"
};
export var hasRole = function hasRole(roleEntity, roleId) {
  return roleEntity.roles.some(function (role) {
    return role.id === roleId;
  });
};
export var operationForms = {
  NEW: "NEW",
  UPDATE: "UPDATE",
  PEND: "PEND",
  SUBMIT: "SUBMIT",
  COMMIT: "COMMIT",
  REJECT: "REJECT"
};
export var filterModes = {
  STARTING_WITH: "startingWith",
  ENDING_WITH: "endingWith",
  CONTAINING: "containing"
};
export var errorCodes = {
  INVESTMENTACCOUNTREADINESS_ERRORS_FOUND: "INVESTMENTACCOUNTREADINESS_ERRORS_FOUND",
  INVESTMENTACCOUNT_NOT_FOUND: "INVESTMENTACCOUNT_NOT_FOUND",
  INVALID_OPERATION_NEXT_STATE: "INVALID_OPERATION_NEXT_STATE",
  OPPOSITE_SIGNS_OPERATION_SHARES_AND_REQUESTEDCURRENCYAMOUNT: "OPPOSITE_SIGNS_OPERATION_SHARES_AND_REQUESTEDCURRENCYAMOUNT",
  BANKACCOUNT_NOT_FOUND: "BANKACCOUNT_NOT_FOUND",
  USER_CUIL_NOT_FOUND: "USER_CUIL_NOT_FOUND",
  MARKET_IS_CLOSED: "MARKET_IS_CLOSED",
  EXTERNAL_BALANCE_NOT_FOUND: "EXTERNAL_BALANCE_NOT_FOUND",
  INSUFFICIENT_CASH: "INSUFFICIENT_CASH",
  TEXT_NOT_READABLE: "TEXT_NOT_READABLE",
  ID_CARD_NOT_DETECTED: "ID_CARD_NOT_DETECTED",
  INVALID_NUMBER_OF_FACES_DETECTED: "INVALID_NUMBER_OF_FACES_DETECTED",
  FACE_NOT_DETECTED: "FACE_NOT_DETECTED",
  SMILE_DETECTED: "SMILE_DETECTED",
  FACE_OCCLUDED: "FACE_OCCLUDED",
  EYEGLASSES_DETECTED: "EYEGLASSES_DETECTED",
  SUNGLASSES_DETECTED: "SUNGLASSES_DETECTED",
  SMILE_NOT_DETECTED: "SMILE_NOT_DETECTED",
  FACES_NOT_SIMILAR: "FACES_NOT_SIMILAR"
};
export var investmentAccountReadinessErrors = {
  ACCOUNT_INCOMPLETE: "ACCOUNT_INCOMPLETE",
  PERSONALINFORMATIONFIRSTPART_INCOMPLETE: "PERSONALINFORMATIONFIRSTPART_INCOMPLETE",
  PERSONALINFORMATIONSECONDPART_INCOMPLETE: "PERSONALINFORMATIONSECONDPART_INCOMPLETE",
  ADDRESS_INCOMPLETE: "ADDRESS_INCOMPLETE",
  BANKACCOUNTS_INCOMPLETE: "BANKACCOUNTS_INCOMPLETE",
  DNIFRONTSIDE_NOT_FOUND: "DNIFRONTSIDE_NOT_FOUND",
  DNIBACKSIDE_NOT_FOUND: "DNIBACKSIDE_NOT_FOUND",
  SIGNATUREDOCUMENT_NOT_FOUND: "SIGNATUREDOCUMENT_NOT_FOUND",
  STUDENTPROOF_NOT_FOUND: "STUDENTPROOF_NOT_FOUND",
  SELFIENEUTRAL_NOT_FOUND: "SELFIENEUTRAL_NOT_FOUND",
  SELFIESMILING_NOT_FOUND: "SELFIESMILING_NOT_FOUND",
  UIFDOCUMENT_NOT_FOUND: "UIFDOCUMENT_NOT_FOUND",
  DEFAULTBROKER_NOT_FOUND: "DEFAULTBROKER_NOT_FOUND",
  CEDULAFRONTSIDE_NOT_FOUND: "CEDULAFRONTSIDE_NOT_FOUND",
  CEDULABACKSIDE_NOT_FOUND: "CEDULABACKSIDE_NOT_FOUND"
};
export var addressTypes = {
  BIRTH: "BIRTH",
  FISCAL: "FISCAL",
  HOME: "HOME"
};
export var contactTypes = {
  SPOUSE: "SPOUSE"
};
export var bankAccountTypeNames = {
  CURRENT_ACCOUNT: "CURRENT_ACCOUNT",
  SIGHT_ACCOUNT: "SIGHT_ACCOUNT",
  SAVINGS_ACCOUNT: "SAVINGS_ACCOUNT",
  INTERNATIONAL_ACCOUNT: "INTERNATIONAL_ACCOUNT"
};
export var hasInvestmentAccountReadinessError = function hasInvestmentAccountReadinessError(person, investmentAccountReadinessError, countryId) {
  return person.investmentAccountReadinessErrorsByCountryId[countryId] && person.investmentAccountReadinessErrorsByCountryId[countryId].some(function (error) {
    return error === investmentAccountReadinessError;
  });
};
export var brandPrimary = "#9c45b8";
export var brandPrimaryFocus = "#632576";
export var brandSecondary = "#2ab182";
export var brandSecondaryFocus = "#208864";
export var brandSuccess = "#28a745";
export var brandInfo = "#17a2b8";
export var brandWarning = "#ffc107";
export var brandDanger = "#dc3545";

// color palette: #3366CC, #DC3912, #FF9900, #109618, #990099, #3B3EAC, #0099C6, #DD4477, #66AA00, #B82E2E, #316395, #994499, #22AA99, #AAAA11, #6633CC, #E67300, #8B0707, #329262, #5574A6, #3B3EAC
export var colorPalette = function colorPalette(alpha) {
  return ["rgba(51, 102, 204, ".concat(alpha, ")"), //'#3366CC',
  "rgba(220, 57, 18, ".concat(alpha, ")"), //'#DC3912',
  "rgba(255, 153, 0, ".concat(alpha, ")"), // '#FF9900',
  "rgba(16, 150, 24, ".concat(alpha, ")"), // '#109618',
  "rgba(153, 0, 153, ".concat(alpha, ")"), // '#990099',
  "rgba(59, 62, 172, ".concat(alpha, ")"), // '#3B3EAC',
  "rgba(0, 153, 198, ".concat(alpha, ")"), // '#0099C6',
  "rgba(221, 68, 119, ".concat(alpha, ")"), // '#DD4477',
  "rgba(102, 170, 0, ".concat(alpha, ")"), // '#66AA00',
  "rgba(184, 46, 46, ".concat(alpha, ")"), // '#B82E2E',
  "rgba(49, 99, 149, ".concat(alpha, ")"), // '#316395',
  "rgba(153, 68, 153, ".concat(alpha, ")"), // '#994499',
  "rgba(34, 170, 153, ".concat(alpha, ")"), // '#22AA99',
  "rgba(170, 170, 17, ".concat(alpha, ")"), // '#AAAA11',
  "rgba(102, 51, 204, ".concat(alpha, ")"), // '#6633CC',
  "rgba(230, 115, 0, ".concat(alpha, ")"), // '#E67300',
  "rgba(139, 7, 7, ".concat(alpha, ")"), // '#8B0707',
  "rgba(50, 146, 98, ".concat(alpha, ")"), // '#329262',
  "rgba(85, 116, 166, ".concat(alpha, ")"), // '#5574A6',
  "rgba(59, 62, 172, ".concat(alpha, ")")];
}; // '#3B3EAC']

export var stringToHashCode = function stringToHashCode(input) {
  var hash = 0,
    i,
    chr;
  if (input.length === 0) return hash;
  for (i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
};
export var stringToColor = function stringToColor(input, alpha) {
  var colorPaletteArr = colorPalette(alpha);
  var output = Math.abs(stringToHashCode(input));
  if (output > colorPaletteArr.length) output = output % colorPaletteArr.length;
  return colorPaletteArr[output];
};

// convert Hex to RGBA
export var convertHex = function convertHex(hex, opacity) {
  hex = hex.replace("#", "");
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);
  var result = "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
  return result;
};
export var numberWithCommas = function numberWithCommas(num) {
  var parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};
export var kFormatter = function kFormatter(num) {
  if (Math.abs(num) > 999999) return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M";
  if (Math.abs(num) > 999) return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K";
  return Math.sign(num) * Math.abs(num);
};
export var exifOrientationValues = {
  TOP_LEFTSIDE: 1,
  TOP_RIGHTSIDE: 2,
  BOTTOM_RIGHTSIDE: 3,
  BOTTOM_LEFTSIDE: 4,
  LEFTSIDE_TOP: 5,
  RIGHTSIDE_TOP: 6,
  RIGHTSIDE_BOTTOM: 7,
  LEFTSIDE_BOTTOM: 8
};
export var urlSearchParams = function urlSearchParams(params) {
  var esc = encodeURIComponent;
  return Object.keys(params).filter(function (k) {
    return params[k] || params[k] === "" || params[k] === false || params[k] === 0;
  }).map(function (k, i) {
    return (i === 0 ? "?" : "") + esc(k) + "=" + esc(params[k]);
  }).join("&");
};
export var secondsToDhms = function secondsToDhms(inputInSeconds) {
  inputInSeconds = Number(inputInSeconds);
  var d = Math.floor(inputInSeconds / 86400);
  var h = Math.floor(inputInSeconds % 86400 / 3600);
  var m = Math.floor(inputInSeconds % 86400 % 3600 / 60);
  var s = Math.floor(inputInSeconds % 86400 % 3600 % 60);
  var dDisplay = d > 0 ? d + "d " : "";
  var hDisplay = h > 0 ? h + "h " : "";
  var mDisplay = d === 0 && m > 0 ? m + "m " : "";
  var sDisplay = d === 0 && h === 0 && s > 0 ? s + "s" : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
};

/**
 * Round half away from zero ('commercial' rounding)
 * Uses correction to offset floating-point inaccuracies.
 * Works symmetrically for positive and negative numbers.
 */
export var round = function round(num, scale) {
  var p = Math.pow(10, scale);
  var n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
};
export var isMobile = window.innerWidth <= 768;
export var calculateUnitDecimalScaleValue = function calculateUnitDecimalScaleValue(unitDecimalScales, broker) {
  return broker ? unitDecimalScales.find(function (unitDecimalScale) {
    return unitDecimalScale.broker.id === broker.id;
  }).value : Math.max.apply(Math, _toConsumableArray(unitDecimalScales.map(function (unitDecimalScale) {
    return unitDecimalScale.value;
  })));
};
export var previousWorkday = function previousWorkday(workday) {
  var day = workday.day();
  var diff = 1; // returns yesterday
  if (day == 0 || day == 1) {
    // is Sunday or Monday
    diff = day + 2; // returns Friday
  }

  return workday.subtract(diff, "days").startOf("day");
};
export var roundDown = function roundDown(number, precision) {
  var power = Math.pow(10, precision);
  return Math.floor(number * power) / power;
};
export var validEmail = function validEmail(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(email);
};
export var validUsername = function validUsername(username) {
  return /^[a-zA-Z0-9_]+$/i.test(username);
};
export var mediaSizes = {
  W274H182: "w274h182",
  W416H277: "w416h277",
  W738H145: "w738h145",
  W854H145: "w854h145"
};
export var walletTabs = {
  DISTRIBUTION: "distribution",
  PERFORMANCE: "performance"
};
export var investmentItemTabs = {
  MENTIONS: "mentions",
  PERFORMANCE: "performance",
  MORE_INFO: "more-info"
};
export var goalTabs = {
  MENTIONS: "mentions",
  PERFORMANCE: "performance",
  PER_INVESTMENT_ITEM: "per-investment-item",
  DISTRIBUTION: "distribution",
  MEMBERS: "members"
};
export var userTabs = {
  MENTIONS: "mentions",
  POSTS: "posts",
  GOALS: "goals",
  ABOUT: "about",
  OPERATIONS: "operations",
  NOTIFICATIONS: "notifications"
};
export var brokerTabs = {
  DISTRIBUTION: "distribution",
  PERFORMANCE: "performance"
};
export var noGoalTabs = {
  PERFORMANCE: "performance",
  PER_INVETMENT_ITEM: "perInvestmentItem",
  DISTRIBUTION: "distribution"
};
export var goalsTabs = {
  NONFINALIZED: "nonFinalized",
  FINALIZED: "finalized"
};
export var boostsTabs = {
  NONFINALIZED: "nonFinalized",
  FINALIZED: "finalized"
};
export var eventsTabs = {
  FOLLOWING: "following",
  FOR_YOU: "for-you",
  MEDIA_ONLY: "media-only",
  HASHTAGS: "hashtags"
};

// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
// Example Lighten:
// shadeColor("#63C6FF",40);
// Example Darken:
// shadeColor("#63C6FF",-40);
export var shadeColor = function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);
  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);
  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;
  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);
  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);
  return "#" + RR + GG + BB;
};
function getAbsoluteDinero(d) {
  return Dinero({
    amount: Math.abs(d.getAmount())
  });
}
export function dollarsToCents(dollars) {
  return parseInt((dollars * 100).toFixed(0));
}
export function getLazyRebalances(isDeposit, withdrawDeposit, targetBalance, investments) {
  var remaining = withdrawDeposit;
  return targetBalance.allocate(investments.map(function (investment) {
    return investment.target || 0;
  })).map(function (allocation, index) {
    // Do not rebalance if there are no remaining funds.
    if (remaining.isZero()) {
      return Dinero();
    }
    var balance = Dinero({
      amount: dollarsToCents(investments[index].balance || 0)
    });

    // Do not rebalance investment if it will force a reverse transfer.
    if (isDeposit ? balance.greaterThan(allocation) : balance.lessThan(allocation)) {
      return Dinero();
    }
    var rebalance = Dinero.minimum([remaining, getAbsoluteDinero(allocation.subtract(balance))]);
    remaining = remaining.subtract(rebalance);
    return rebalance;
  }).map(function (d) {
    return d.multiply(isDeposit ? 1 : -1);
  });
}