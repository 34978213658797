function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import * as ActionTypes from "../../actions";
import { operationStateIds, memberStates } from "../../constants";

// Updates an entity cache in response to any action with response.entities.
var operationEntities = function operationEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byCurrencyIdAndGoalIdAndUserIdAndStateIdIn: {},
    byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan: {},
    byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan: {},
    byBrokerIdAndGoalIdAndUserIdAndStateIdIn: {},
    byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan: {},
    byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan: {},
    byGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter: {},
    byUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot: {},
    byUserIdAndStateNot: {},
    byId: {},
    byBrokerId: {},
    byGoalIdAndUserIdAndStateIdIn: {},
    byUserIdAndStateIdIn: {},
    byCurrencyIdAndInvestmentItemIdAndUserIdAndStateIdIn: {},
    depositsByUserIdAndStateId: {},
    rebalance: {},
    paymentsByUserId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_OPERATIONBYID_SUCCESS:
      if (!action.response) return state;
      var byId = _objectSpread({}, state.byId);
      byId[action.id] = action.response;
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.LOGIN_SUCCESS:
    case ActionTypes.GET_AUTHENTICATIONSTATUS_SUCCESS:
      var key = action.response.principal && action.response.principal.id;
      var notificationListItems = action.response.notificationListItemsByUserId[key];
      if (!notificationListItems) return state;
      var byId = _objectSpread({}, state.byId);
      notificationListItems.forEach(function (notificationListItem) {
        return merge(byId, notificationListItem.operationById);
      });
      return merge({}, state, {
        byId: byId
      });
    case ActionTypes.GET_NOTIFICATIONTABBYUSERID_SUCCESS:
      var key = action.userId;
      var notificationListItems = action.response.notificationListItemsByUserId[key];
      if (!notificationListItems) return state;
      var byId = _objectSpread({}, state.byId);
      notificationListItems.forEach(function (notificationListItem) {
        return merge(byId, notificationListItem.operationById);
      });
      return merge({}, state, {
        byId: byId
      });
    case ActionTypes.GET_NOTIFICATIONTOASTSBYUSERID_SUCCESS:
    case ActionTypes.GET_NOTIFICATIONTOASTSBYUSERIDANDNOTIFIATIONVIEWEDATISNULL_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      action.response.forEach(function (notificationListItem) {
        return merge(byId, notificationListItem.operationById);
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.GET_EVENTSVIEWBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      var key = [action.userId, action.eventTypeNames].join("-");
      action.response.eventListItemsByUserIdAndEventTypeNameIn[key].forEach(function (eventListItem) {
        return merge(byId, eventListItem.operationById);
      });
      return merge({}, state, {
        byId: byId
      });
    case ActionTypes.GET_EVENTLISTITEMSBYUSERIDANDEVENTTYPENAMEIN_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      action.response.forEach(function (eventListItem) {
        return merge(byId, eventListItem.operationById);
      });
      return merge({}, state, {
        byId: byId
      });
    case ActionTypes.GET_OPERATIONSBYBROKERIDANDGOALIDANDUSERIDANDSTATEIDIN_SUCCESS:
      var byBrokerIdAndGoalIdAndUserIdAndStateIdIn = _objectSpread({}, state.byBrokerIdAndGoalIdAndUserIdAndStateIdIn);
      var key = [action.brokerId, action.goalId || "null", action.userId, action.stateIds].join("-");
      var embedded = action.response._embedded ? action.response._embedded.operations : [];
      byBrokerIdAndGoalIdAndUserIdAndStateIdIn[key] = embedded;
      return Object.assign({}, state, {
        byBrokerIdAndGoalIdAndUserIdAndStateIdIn: byBrokerIdAndGoalIdAndUserIdAndStateIdIn
      });
    case ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS:
      var byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan = _objectSpread({}, state.byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan);
      var key = [action.brokerId, action.currencyId, action.goalId || "null", action.userId, action.stateIds, action.requestedCurrencyAmount].join("-");
      var embedded = action.response._embedded ? action.response._embedded.operations : [];
      byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan[key] = embedded;
      return Object.assign({}, state, {
        byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan: byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan
      });
    case ActionTypes.GET_OPERATIONSBYBROKERIDANDCURRENCYIDANDGOALIDANDINVESTMENTITEMIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS:
      var byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan = _objectSpread({}, state.byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan);
      var key = [action.brokerId, action.currencyId, action.goalId || "null", action.investmentItemId, action.userId, action.stateIds, action.requestedCurrencyAmount].join("-");
      var embedded = action.response._embedded ? action.response._embedded.operations : [];
      byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan[key] = embedded;
      return Object.assign({}, state, {
        byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan: byBrokerIdAndCurrencyIdAndGoalIdAndInvestmentItemIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan
      });
    case ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTGREATERTHAN_SUCCESS:
      var byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan = _objectSpread({}, state.byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan);
      var key = [action.goalId || "null", action.userId, action.stateIds, action.requestedCurrencyAmount].join("-");
      var embedded = action.response._embedded ? action.response._embedded.operations : [];
      byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan[key] = embedded;
      return Object.assign({}, state, {
        byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan: byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan
      });
    case ActionTypes.GET_OPERATIONSBYGOALIDANDUSERIDANDSTATEIDINANDREQUESTEDCURRENCYAMOUNTLESSTHAN_SUCCESS:
      var byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan = _objectSpread({}, state.byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan);
      var key = [action.goalId || "null", action.userId, action.stateIds, action.requestedCurrencyAmount].join("-");
      var embedded = action.response._embedded ? action.response._embedded.operations : [];
      byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan[key] = embedded;
      return Object.assign({}, state, {
        byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan: byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan
      });
    case ActionTypes.GET_DEPOSITSBYUSERIDANDSTATEID_SUCCESS:
      var depositsByUserIdAndStateId = _objectSpread({}, state.depositsByUserIdAndStateId);
      var key = [action.userId, action.stateId].join("-");
      if (depositsByUserIdAndStateId[key] && action.sinceId !== undefined) depositsByUserIdAndStateId[key] = action.response.reverse().concat(depositsByUserIdAndStateId[key]);else if (depositsByUserIdAndStateId[key] && action.maxId !== undefined) depositsByUserIdAndStateId[key] = depositsByUserIdAndStateId[key].concat(action.response);else depositsByUserIdAndStateId[key] = action.response;
      return Object.assign({}, state, {
        depositsByUserIdAndStateId: depositsByUserIdAndStateId
      });
    case ActionTypes.GET_OPERATIONSBYUSERIDANDSTATEIDINANDCASHOPOFTRANSACTIONTYPEBUYORSELLNOT_SUCCESS:
      var byUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot = _objectSpread({}, state.byUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot);
      var key = [action.userId, action.stateIds].join("-");
      if (byUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot[key] && action.sinceId !== undefined) byUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot[key] = action.response.reverse().concat(byUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot[key]);else if (byUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot[key] && action.maxId !== undefined) byUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot[key] = byUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot[key].concat(action.response);else byUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot[key] = action.response;
      var byId = _objectSpread({}, state.byId);
      action.response && action.response.forEach(function (operation) {
        return byId[operation.id] = operation;
      });
      return Object.assign({}, state, {
        byUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot: byUserIdAndStateIdInAndCashOpOfTransactionTypeBuyOrSellNot,
        byId: byId
      });
    case ActionTypes.GET_OPERATIONS_SUCCESS:
    case ActionTypes.GET_OPERATIONSBYBROKERID_SUCCESS:
      var embedded = action.response._embedded;
      if (!embedded) return state;
      var byId = _objectSpread({}, state.byId);
      embedded.operations.forEach(function (operation) {
        return byId[operation.id] = operation;
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.POST_OPERATION_SUCCESS:
      var embedded = action.response._embedded;
      if (!embedded) return state;
      var byId = _objectSpread({}, state.byId);
      byId[embedded.id] = embedded;
      var byUserIdAndStateIdIn = _objectSpread({}, state.byUserIdAndStateIdIn);
      var engagedOperationStates = [operationStateIds.PENDING, operationStateIds.SUBMITTED].join(",");
      var key = [embedded.user.id, engagedOperationStates].join("-");
      var engagedOperations = byUserIdAndStateIdIn[key];
      if ((embedded.state.id === operationStateIds.PENDING || embedded.state.id === operationStateIds.SUBMITTED) && !engagedOperations) byUserIdAndStateIdIn[key] = [embedded];else if ((embedded.state.id === operationStateIds.PENDING || embedded.state.id === operationStateIds.SUBMITTED) && !engagedOperations.find(function (operation) {
        return operation.id === embedded.id;
      })) engagedOperations.push(embedded);
      return Object.assign({}, state, {
        byId: byId,
        byUserIdAndStateIdIn: byUserIdAndStateIdIn
      });
    case ActionTypes.POST_OPERATIONS_SUCCESS:
      var embedded = action.response._embedded;
      if (!embedded) return state;
      var byId = _objectSpread({}, state.byId);
      embedded.forEach(function (operation) {
        return byId[operation.id] = operation;
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.DELETE_OPERATION_SUCCESS:
      var byCurrencyIdAndGoalIdAndUserIdAndStateIdIn = _objectSpread({}, state.byCurrencyIdAndGoalIdAndUserIdAndStateIdIn);
      Object.keys(state.byCurrencyIdAndGoalIdAndUserIdAndStateIdIn).map(function (key) {
        byCurrencyIdAndGoalIdAndUserIdAndStateIdIn[key] = state.byCurrencyIdAndGoalIdAndUserIdAndStateIdIn[key] && state.byCurrencyIdAndGoalIdAndUserIdAndStateIdIn[key].filter(function (operation) {
          return operation.id !== action.operation.id;
        });
      });
      var byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan = _objectSpread({}, state.byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan);
      Object.keys(state.byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan).map(function (key) {
        byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan[key] = state.byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan[key].filter(function (operation) {
          return operation.id !== action.operation.id;
        });
      });
      var byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan = _objectSpread({}, state.byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan);
      Object.keys(state.byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan).map(function (key) {
        byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan[key] = state.byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan[key].filter(function (operation) {
          return operation.id !== action.operation.id;
        });
      });
      var byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan = _objectSpread({}, state.byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan);
      Object.keys(state.byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan).map(function (key) {
        byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan[key] = state.byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan[key].filter(function (operation) {
          return operation.id !== action.operation.id;
        });
      });
      var byUserIdAndStateNot = _objectSpread({}, state.byUserIdAndStateNot);
      Object.keys(state.byUserIdAndStateNot).map(function (key) {
        byUserIdAndStateNot[key] = state.byUserIdAndStateNot[key].filter(function (operation) {
          return operation.id !== action.operation.id;
        });
      });
      var byId = _objectSpread({}, state.byId);
      delete byId[action.operation.id];
      var byBrokerId = _objectSpread({}, state.byBrokerId);
      Object.keys(state.byBrokerId).map(function (key) {
        byId[byBrokerId] = state.byId[byBrokerId].filter(function (operation) {
          return operation.id !== action.operation.id;
        });
      });
      return Object.assign({}, state, {
        byCurrencyIdAndGoalIdAndUserIdAndStateIdIn: byCurrencyIdAndGoalIdAndUserIdAndStateIdIn,
        byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan: byBrokerIdAndCurrencyIdAndGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan,
        byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan: byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountGreaterThan,
        byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan: byGoalIdAndUserIdAndStateIdInAndRequestedCurrencyAmountLessThan,
        byUserIdAndStateNot: byUserIdAndStateNot,
        byId: byId,
        byBrokerId: byBrokerId
      });
    case ActionTypes.GET_MEMBERSVIEWBYUSERID_SUCCESS:
      var byGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter = _objectSpread({}, state.byGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter);
      var nonFinalizedMemberStates = [memberStates.DELETABLE, memberStates.ONGOING, memberStates.PENDING_FINALIZE].join(",");
      var key = [action.userId, nonFinalizedMemberStates].join("-");
      action.response.memberListItemsByMemberUserIdAndMemberStateIn[key].forEach(function (memberListItem) {
        return merge(byGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter, memberListItem.operationsByGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter);
      });
      return merge({}, state, {
        byGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter: byGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter
      });
    case ActionTypes.GET_MEMBERLISTITEMSBYMEMBERUSERIDANDMEMBERSTATES_SUCCESS:
      var byCurrencyIdAndGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter = _objectSpread({}, state.byCurrencyIdAndGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter);
      action.response.forEach(function (card) {
        return merge(byCurrencyIdAndGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter, card.operationsByCurrencyIdAndGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter);
      });
      return merge({}, state, {
        byCurrencyIdAndGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter: byCurrencyIdAndGoalIdAndUserIdAndStateIdInAndSubmittedAtAfter
      });
    case ActionTypes.GET_DISTRIBUTIONTABBYGOALIDANDUSERID_SUCCESS:
      var byGoalIdAndUserIdAndStateIdIn = action.response.operationsByGoalIdAndUserIdAndStateIdIn;
      return merge({}, state, {
        byGoalIdAndUserIdAndStateIdIn: byGoalIdAndUserIdAndStateIdIn
      });
    case ActionTypes.GET_CARTVIEWBYUSERIDANDOPERATIONSTATEID_SUCCESS:
    case ActionTypes.GET_WALLETVIEWBYUSERID_SUCCESS:
      var byUserIdAndStateIdIn = action.response.operationsByUserIdAndStateIdIn;
      return merge({}, state, {
        byUserIdAndStateIdIn: byUserIdAndStateIdIn
      });
    case ActionTypes.GET_OPERATIONFORMBYINVESTMENTITEMIDANDUSERID_SUCCESS:
      var byCurrencyIdAndInvestmentItemIdAndUserIdAndStateIdIn = action.response.operationsByCurrencyIdAndInvestmentItemIdAndUserIdAndStateIdIn;
      return merge({}, state, {
        byCurrencyIdAndInvestmentItemIdAndUserIdAndStateIdIn: byCurrencyIdAndInvestmentItemIdAndUserIdAndStateIdIn
      });
    case ActionTypes.GET_OPERATIONSBYCURRENCYIDANDGOALIDANDUSERIDANDSTATEIDIN_SUCCESS:
      var key = [action.currencyId, action.goalId || "null", action.userId, action.stateIds].join("-");
      var byCurrencyIdAndGoalIdAndUserIdAndStateIdIn = _objectSpread({}, state.byCurrencyIdAndGoalIdAndUserIdAndStateIdIn);
      byCurrencyIdAndGoalIdAndUserIdAndStateIdIn[key] = action.response._embedded.operations;
      return merge({}, state, {
        byCurrencyIdAndGoalIdAndUserIdAndStateIdIn: byCurrencyIdAndGoalIdAndUserIdAndStateIdIn
      });

    // case ActionTypes.GET_PENDINGDEPOSITSVIEWBYUSERID_SUCCESS:
    //   var depositsByUserIdAndState = action.response.depositsByUserIdAndState;
    //   return Object.assign({}, state, { depositsByUserIdAndState });

    case ActionTypes.GET_USERVIEWBYFOLLOWEEUSERNAMEANDAUTHUSERID_SUCCESS:
      var paymentsByUserId = action.response.paymentOperationsByUserId;
      return merge({}, state, {
        paymentsByUserId: paymentsByUserId
      });
    default:
      return state;
  }
};
export default operationEntities;